
body > #root > div {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
body{
  margin: 0px;
  font-family: 'Montserrat', sans-serif;
}

#root h1.ui.header{
}

#root .ui.button{
  font-weight: normal;
  font-size: 18px;
  height: 64px;
}
#root .ui.form input{
  font-size: 20px;
  height: 64px;
}

#root .ui.button.primary{
  height: 64px;
}
div, span, footer{
}
#root a{
  text-decoration: underline;
  cursor: pointer;
}

#root .MuiButtonBase-root.MuiButton-contained {
  height: 64px;
	font-weight: normal;
}

#root button.MuiButtonBase-root .MuiButton-label{
	font-size: 18px;
}

#root .aux-buttons > button > span.MuiButton-label {
}

#root .aux-buttons > button > span.MuiButton-label img {
	margin-right:3px;
}

#root .MuiInputBase-root{
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
}

#root .question-zipcode > div > div {
	font-size:18px;
}

#root .MuiButton-root {
	font-weight:400;
}

.nav_account_menu .MuiListItem-gutters {
    padding-left: 24px;
    padding-right: 24px;
}

.odometer.odometer-auto-theme, .odometer.odometer-theme-minimal {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-minimal .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-minimal .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-minimal .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-minimal .odometer-digit .odometer-ribbon {
  display: block;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-minimal .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-minimal .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-minimal .odometer-digit .odometer-value.odometer-last-value {
  position: absolute;
}
.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-up .odometer-ribbon-inner {
  transition: transform 2s;
}
.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-down .odometer-ribbon-inner {
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  transition: transform 2s;
  transform: translateY(0);
}
.screen_signup{
    min-height: auto;  
    display: flex;
    align-items: center;
    justify-content: center;
	background:#041e10;
    padding-bottom: 50px;
}
.screen_signup .wrapper{
    flex: 1 1;
    max-width: 400px;
    display: flex;
    flex-direction: column;
		
		background: #F9F9FD;
		border-radius: 4px;
		padding: 20px 20px 40px;
}
.screen_signup .wrapper form{
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}
.screen_signup h1{
    text-align: center; 
    font-size: 22px;
		font-weight:500;
}

.screen_signup .profile_field{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;    
}
.screen_signup footer{
    margin-top: 30px;
    text-align: center;
}
.screen_signup .check-avatar{
    margin-left: 10px;
}
.screen_signup .double-field{
    display: flex;
    justify-content: space-between;
}
#root .screen_signup .profile-button.MuiButtonBase-root{
    margin-top: 16px;
    margin-bottom: 8px;    
    justify-content: space-between;
    height: 56px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;   
		background:#ffffff;
		font-weight:normal;
}
.screen_signup .profile-button .MuiButton-label{
    text-transform: none;
}
.screen_signup .hide {
    display: none !important;

}

.screen_signup .MuiInputBase-input {
	border-radius:4px;
	background-color:#ffffff;
}

#root .screen_signup button.MuiButtonBase-root .MuiButton-label {
	font-size: 16px;
}
.terms {
    margin-top: 5px;
    font-size: .9rem;
    align-items: flex-end;
    line-height: 1.3rem;
}
.mfa {
    margin-top: 15px;
    font-size: .9rem;
    align-items: flex-end;
    line-height: 1.3rem;
}
@media screen and ( min-height: 780px ) {

    .screen_signup{
        height: 100vh;
    }
}

.screen_login{
    height: auto;  
    display: flex;
    align-items: center;
    justify-content: center;
		background:#041e10;
}
.screen_login .wrapper{
    flex: 1 1;
    max-width: 400px;
    display: flex;
    flex-direction: column;
		
		background: #F9F9FD;
		border-radius: 4px;
		padding: 20px 20px 40px;
}
.screen_login .wrapper form{
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}
.screen_login h1{
    text-align: center;
    font-size: 32px;
		font-weight:500;
}
.screen_login footer{
    margin-top: 30px;
    text-align: center;
    font-size: 16px;
}
.screen_login .ui.message {
    text-align: center;
}

.screen_login .MuiInputBase-input {
	border-radius:4px;
	background-color:#ffffff;
}


#root .screen_login button.MuiButtonBase-root .MuiButton-label {
    font-size: 16px;
}
@media screen and ( min-height: 565px ) {

    .screen_login{
        height: 100vh;
    }
}

.nav_component{
    display: flex;
    background: #041E10;
		flex-direction: column;
		position: relative;
		width: 100%;
}
.nav_component header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
		padding: 20px 40px 0;
		z-index: 10;
}
.nav_footprint-graph .title{
    color: #FFFFFF;
    font-size: 16px;    
}

.nav_component .separator{
    display: none;
}
.nav_component .nav_logo,.nav_component .nav_account {
    cursor: pointer;
}

.nav_component .nav_logo{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: center;
  align-content: stretch;
}

.nav_component .nav_logo img{
    height: 20px;    
    left: 40px;
    top: 15px;
    border-radius: 0px;
}
.nav_component .nav_account{
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: 10;
}
.nav_component .nav_account .nav_account_avatar{
	display:none;
    /**width: 30px;
    height: 30px;
    border-radius: 30px;
    margin-right: 10px;**/
}
.nav_component .nav_account .nav_account_name{
    color: #FFFFFF;
    font-size: 18px;
}

.nav_account > div > button {
	text-transform: none;
}

.nav_component .nav_account .nav_account_menu{
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 5px;
}
.nav_component .nav_account i.icon{
    color: #FFFFFF;
    font-size: 19px;
    position: relative;    
}
.nav_account_menu .MuiButton-label{
    color: #FFFFFF;
}
.nav_component footer .MuiPaper-root{
    background-color: transparent;
    max-width: 800px;
    width: 100%;
		 padding-top: 20px;
     padding:0px 0px 15px;
}

.nav_component footer .MuiStepLabel-iconContainer .completed {
    color: #78E176;
}

.nav_component footer .MuiStepLabel-iconContainer .circle{
    width: 20px;
    height: 20px;
    background-color:rgba(255, 255, 255, 0.11);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Space Mono';
    line-height: 100%;
    color: #041E10;
}
.nav_component footer .MuiStepLabel-iconContainer .active .circle{
    background-color: #78E176;
}

.nav_component footer .MuiStepLabel-label{
    color: rgba(255, 255, 255, 0.3);
    text-transform: uppercase;
    letter-spacing: 1px;
		font-weight: 400;
}

.nav_component footer .MuiStepLabel-label.MuiStepLabel-active,.nav_component footer .MuiStepLabel-label.MuiStepLabel-completed {
    color: #FFFFFF;
}

.nav_component footer .MuiStepConnector-line.MuiStepConnector-lineHorizontal{
    border-color: rgba(255, 255, 255, 0.25);
    border-width: 2px;
}
.nav_component footer{    
    display: flex;
    justify-content: center;
}
.nav_component footer .MuiStepIcon-root.MuiStepIcon-completed {
    color: #78E176;
}
.nav_component .MuiStep-root{
    height: 28px;
    display: flex;
		/** Test **/
    
	}
.nav_component .MuiStepLabel-root{
    cursor: pointer !important;
}

.nav_component .version{
	opacity: 0.6;
	font-size: 12px;
	text-align: center;
	margin: 14px 10px 5px;
	letter-spacing: 1px;
}
.nav_footprint{
    
    display: flex;
    justify-content: center;
}
.nav_footprint-graph{    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 377px;
    position: relative;
}


/**
@media only screen and (max-width: 420px) {
    .nav_component header{
        justify-content: center;
    }
    .nav_component header .nav_account{
        display: none;
        width: 0px;
    }
    .nav_component header .nav_logo{
        width: 0px;
        display: none;
    }
  }
**/

/** Small Devices: Portrait & Landscape **/
@media (max-width: 576px) {
  
	.nav_component {
		position:relative;
		width: auto;
	}
	
	.nav_component header {
		padding: 12px 20px 0;
	}
	
	.nav_component header .nav_logo img {
      width: 120px;
  }
	.nav_component .nav_account .nav_account_avatar {
		display:none;
	}
}
.navbar-select{
    display: none;
}

/** Small devices (landscape phones, less than 768px) **/
@media (max-width: 767.98px) {

}

@media only screen and (max-width: 845px) {
    .nav_footprint-graph{
        height: 500px;
    }
}

@media only screen and (max-width: 576px) {
    .nav_component .separator{
        display: block;
    }
    .nav_component footer{
      display: none;
    }
    .nav_component header{
        margin-bottom: 20px;
    }
    .nav_component header > div{
        width: 120px;
    }
    .nav_component .name{
        display: none;
    }
    .navbar-select{
        
        background-color: #F9F9FD;
        display: flex;
    }
    .navbar-select .MuiFormControl-root{
        width: 100vw;
    }
    #root .navbar-select .MuiInputBase-root{
        box-shadow: none;
    }
    .navbar-select .MuiOutlinedInput-notchedOutline{
        border-right-style: none;
        border-left-style: none;
        border-radius: 0px;        
    }
    .navbar-select .MuiSelect-selectMenu{
        text-transform: uppercase;        
    }    
    .navbar-select .navbar-select-menu{
        overflow-x: hidden;
    }
    
    div.MuiSelect-selectMenu >.navbar-select-option{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #1D352899;          
        height: 30px;
        
    }
    div.MuiSelect-selectMenu > .navbar-select-option .navbar-select-option-text{
        height: 20px;
        margin-left: 10px;
    }
    li > .navbar-select-option{
        display: flex;
        justify-content: flex-start;
        align-items: center;        
    }
    li > .navbar-select-option .navbar-select-option-text{
        margin-left: 10px;
        height: 20px;
    }
}

#alert-dialog-title h2{
    font-size: 1.75rem;
    font-weight: bold;
}
#leave-dialog .MuiDialog-paper{
   max-width: 500px; 
}
.footprint_score .title{  
  font-size: 18px;
  line-height:100%;
  text-align: center;  
  color: #FFFFFF;
}
.nav_footprint {
    margin-top: -30px;
}
.footprint_score .footprint-type{  
  margin-top: 16px;
  font-size: 14px;
  color: #FFFFFF;
  opacity: 0.6;
	letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footprint_score .footprint-personal{    
  font-size: 14px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
	margin: 20px 0;
	height: 14px;
}

.footprint_score .footprint-personal .odometer-value {
	font-family: Space Mono, sans-serif;
	line-height: 100%;
}

.footprint_score .score{
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  line-height: 100%;
  text-align: center;
  color: #FFFFFF;
  text-decoration: none;
}
.footprint_score .score .footprint-link{
  color: #FFFFFF;
  text-decoration: none !important;
}
.footprint_score .footprint-summary{
  font-size: 14px;
  line-height:100%;  
  color: #FFFFFF;  
  opacity: 0.6;
  text-align: center;
  margin-top: 6px;
  display: flex;
  justify-content: center;
	letter-spacing: 0.5px;
}
.footprint_score .footprint-personal .personal-label{
	letter-spacing:1px;
}
.footprint_score .score .odometer-value{
  font-family: Space Mono;
}
.footprint-summary .tooltip-button{ 
  color: #FFFFFF; 
}
.footprint-summary .tooltip-button .MuiSvgIcon-root{
  width: 12px;
  height: 12px;
}
.footprint-summary .tooltip-button{
  padding-top: 0px;
  padding-bottom: 0px;
}
.footprint-type .tooltip-button{
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}
.footprint-type .tooltip-button{ 
  color: #FFFFFF; 
}
.footprint-type .tooltip-button .MuiSvgIcon-root{
  width: 12px;
  height: 12px;
}
.footprint-personal .tooltip-button{
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}
.footprint-personal .tooltip-button{ 
  opacity: 0.6;
  color: #FFFFFF; 
}
.footprint-personal .tooltip-button .MuiSvgIcon-root{
  width: 12px;
  height: 12px;
}

/** Small Devices: Portrait & Landscape **/
@media (min-width: 300px) {
	/**.nav_footprint {
	    margin-top: 0px;
	}**/
}
@media only screen and (max-width: 666px) {
  .footprint_score .title{
    display: none;
  }
}

.tooltip-footprint .MuiPopover-paper{
  padding: 15px;
  max-width: 400px;
  font-size: 18px;
}
.tooltip-footprint{
  pointer-events: none;
}
.tooltip-footprint .MuiPopover-paper h4{
  -webkit-margin-before: 0px;
          margin-block-start: 0px;  
  -webkit-margin-after: 10px;  
          margin-block-end: 10px;  
}
.tooltip-button{
  font-size: inherit !important;
  display: inline-flex;
}
.footprint-personal-graph{
  color: #FFFFFF;  
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;	
	height: 22px;
  margin-top: 25px;
}
.footprint-personal-graph .odometer-value {
	font-family: Space Mono, sans-serif;
	line-height: 100%;
}
.footprint-personal-graph .tons{
  text-align: center;
}
.footprint-personal-graph .tooltip-button{ 
  color: #FFFFFF;
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}
.footprint-personal-graph .tooltip-button .MuiSvgIcon-root{
  width: 12px;
  height: 12px;
}

.footprint-personal-graph .odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, 
.footprint-personal-graph .odometer.odometer-theme-minimal .odometer-digit .odometer-digit-inner{
  display: flex;
  align-items: center;
}
.footprint-embed{
  display: flex;
  flex-direction: column;
  width: 260px;
  position: absolute;
  left: calc(50% + 170px);
  top:calc(50% - 110px)
}
.graph-popover{
  pointer-events: none;
}
.graph-popover .MuiPopover-paper{
  padding: 12px;
  
}
.mask-graph{
  top: 26px;
  display: block;  
  position: absolute;
  height: 260px;
  width: 260px;  
  z-index: -1;
}
.graph-popover .graph-popover-title  {
  text-align: center;
  display: block;
  font-size: 14px;

}
.graph-popover .graph-popover-text  {
  text-align: center;
  display: block;
  font-weight: bold;
  font-size: 14px;


}
.footprint-embed .label,
.footprint-embed .value
{
  color: white;
}
.footprint-embed .section_selection {
  justify-content: space-between;
}

@media only screen and (max-width: 845px) {
  .footprint-embed{
    display: flex;
    flex-direction: column;
    width: calc(100% - 48px);
    position: static;
    position: initial;
    max-width: calc(600px - 25px);
  }  
  
}

.footprint_score_graph{
  position: absolute;
  top:105px;
}
.footprint_score_graph .title{  
  font-size: 18px;
  line-height:100%;
  text-align: center;  
  color: #FFFFFF;
}

.footprint_score_graph .footprint-type{  
  max-width: 154px;
  font-size: 14px;
  color: #FFFFFF;
  opacity: 0.6;
	letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footprint_score_graph .footprint-type span{
  pointer-events: none;
  max-width: 110px;
  text-align: center;
}
.footprint_score_graph .footprint-personal{    
  position: relative;
  top: 80px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
	margin: 20px 0;
	height: 14px;
}

.footprint_score_graph .tons{
  text-align: center;
}

.footprint_score_graph .score{
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  color: #FFFFFF;
  text-decoration: none;
}
.footprint_score_graph .score .footprint-link{
  color: #FFFFFF;
  text-decoration: none !important;
}
.footprint_score_graph .footprint-summary{
  font-size: 12px;
  line-height:100%;  
  color: #FFFFFF;  
  opacity: 0.6;
  text-align: center;
  margin-top: 6px;
  display: flex;
  justify-content: center;
	letter-spacing: 0.5px;
}

.footprint_score_graph .score .odometer-value{
  font-family: Space Mono;
}
.footprint_score_graph .footprint-summary .tooltip-button{ 
  color: #FFFFFF; 
}
.footprint_score_graph .footprint-summary .tooltip-button .MuiSvgIcon-root{
  width: 12px;
  height: 12px;
}
.footprint_score_graph .footprint-summary .tooltip-button{
  padding-top: 0px;
  padding-bottom: 0px;
}
.footprint_score_graph .footprint-type .tooltip-button{
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0px;
}
.footprint_score_graph .footprint-type .tooltip-button{ 
  color: #FFFFFF; 
}
.footprint_score_graph .footprint-type .tooltip-button .MuiSvgIcon-root{
  width: 12px;
  height: 12px;
}


/** Small Devices: Portrait & Landscape **/
@media (min-width: 300px) {
	/**.nav_footprint {
	    margin-top: 0px;
	}**/
}
.section_selection{
  display: flex;
  align-items: center;
	color: #041E10;
}
.section_selection .icon{
  margin-right: 10px;
}
.section_selection .label{
  font-size: 16px;
  width: 120px;
  text-transform: capitalize;
}
.section_selection .value{
  text-align: right;
  font-family: Space Mono;
  width: 100px;
	font-weight:700;
}
.section_selection .value span{
  font-family: 'Roboto', sans-serif;
  opacity: 0.6;
  font-size: 14px;
  font-weight: 400;
}
.section_selection .MuiCheckbox-root{
  color: #FFFFFF8A;
}
.section_selection .MuiSvgIcon-root.home{
  color: #239B8A;
}

.section_selection .MuiSvgIcon-root.transport{
  color: #34638B;
  transform: matrix(0.71, 0.73, -0.69, 0.71, 0, 0);
}

.section_selection .MuiSvgIcon-root.food{
  color: #BBBE2C;
}

.section_selection .MuiSvgIcon-root.goods{
  color: #91959B;
}

.section_selection .MuiSvgIcon-root.services{
  color: #5C6068;
}
.section_selection .icon-label{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.section_selection  .MuiCheckbox-root:not(.Mui-checked) .MuiSvgIcon-root{
  color: #041E10;
}
.section_selection .MuiCheckbox-colorPrimary.Mui-checked{
  color: #2c76d2;  
}
/*
#2c76d2
*/
.question-helper{
  margin-right: 21px;
  height: 36px;
}
.question-helper .MuiToggleButton-label{
  font-size: 16px;
  line-height: 100%;
  text-transform: none;
  height: 30px;
	font-weight: normal;
}
.question-helper .MuiToggleButton-root{
  color: rgba(0, 0, 0, 0.54);
  border-color: rgba(0, 0, 0, 0.25);
}

.question-helper .MuiToggleButton-root.Mui-selected{
  color: #041E10;
}
.question-helper .MuiToggleButton-root.Mui-selected:hover{
  background-color: rgba(0, 0, 0, 0.12);
}


.question-text .MuiInputBase-root{
  background: #ffffff;
  max-width: 420px;
  height: 64px;
}
.question-text .MuiInputBase-root fieldset{
  top: -6px;
}
.question-text .MuiOutlinedInput-root{
  background-color: #ffffff;
  font-size: 18px;
}

@media only screen and (max-width: 420px) {  
  .question-text .MuiInputBase-root{
    width: auto;
  }
}
.calculator_question{
  background: #F9F9FD;
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 60px;
}
.calculator_question .wrapper{
  max-width: 800px;  
  min-width: 800px;  
  display: flex;
  flex-direction: column;
  flex:1 1;
  margin-bottom: 50px;
  margin-right: 20px;
  margin-left: 20px;
}
@media only screen and (max-width: 800px) {
  .calculator_question .wrapper{
    width: calc(100% - 40px);
    max-width: none;
    min-width: auto;
  }
}
.calculator_question header{
  font-size: 32px;
  line-height: 140%;
  /* identical to box height, or 50px */
  color: #161C24;
  margin-top: 40px;
  margin-bottom:21px;
}

.calculator_question .MuiPaper-root.info {
	background: rgba(0, 0, 0, 0.04);
	font-size: 16px;
  line-height: 148%;
  margin-bottom: 25px;
  padding: 24px 20px;
}

.calculator_question .MuiPaper-root.info > p:first-child {
	margin-top:0px;
}

.calculator_question .MuiPaper-root.info > p:last-child {
	margin-bottom:0px;
}

.calculator_question .MuiButtonBase-root.submit {
  margin-top: 25px;
  max-width: 140px;
}

#root .calculator_question .MuiButtonBase-root.submit .MuiButton-label{
	font-weight: 500;
	font-size:16px;
}

.calculator_question footer{
  display: flex;
  justify-content: space-between;
  width: 100%;    
  pointer-events: none;
  position: fixed;
  left: 0px;
  bottom: 0px;
}

#root .calculator_question footer .MuiButton-root {
  height: 42px;
	font-size:16px;
  pointer-events: initial;
  transition: none;
}

#root .calculator_question footer .MuiButton-root .MuiButton-label{
	font-size:16px;
}

#root .calculator_question footer a.MuiButton-root {
  text-decoration: none;
}

#root .calculator_question footer .navigation-buttons .MuiButtonBase-root {
  padding:0px;
  min-width: 42px;
  max-width: 42px;
	margin-right:2px;
	border-radius:0px;
}

#root .calculator_question footer .navigation-buttons .nav-btn-prev {
	-webkit-border-top-left-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

#root .calculator_question footer .navigation-buttons .nav-btn-next {
	-webkit-border-top-right-radius: 5px;
	-webkit-border-bottom-right-radius: 5px;
	-moz-border-radius-topright: 5px;
	-moz-border-radius-bottomright: 5px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

#root .calculator_question footer .navigation-buttons .nav-btn-prev svg{
	position:relative;
	left:5px;
}

.calculator_question footer .aux-buttons {
	margin-right: 20px;
  margin-top:  24px;
  margin-bottom: 38px;
}
.calculator_question footer .coolclimate{
  position: absolute;
  right: 20px;
  bottom: 10px;
  font-size: 12px;
  pointer-events: all;
  
}

.calculator_question footer .navigation-buttons {
  margin-left: 20px;
  margin-top:  24px;
  margin-bottom: 38px;
}

.calculator_question footer .aux-buttons .units{
	background:#F9F9FD;
}

.calculator_question footer .aux-buttons .need-help{
  margin-left: 10px;
}
.calculator_question .wrapper-question-helpers{
  display: flex;  
  margin-bottom: 15px;
  height: 36px;
}

button.submit {
	box-shadow:none;
}
.skiplink{
  font-size: 14px;
  margin-top: 10px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  cursor: pointer;
}
@media only screen and (max-width: 576px) {
  .calculator_question footer .need-help{
    display: none;
  }  
  .calculator_question footer{
    background-color: white;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
  }

  .calculator_question footer .aux-buttons {
    margin-right: 10px;
    margin-top:  24px;
    margin-bottom: 30px;
  }
  
  .calculator_question footer .navigation-buttons {
    margin-left: 10px;
    margin-top:  24px;
    margin-bottom: 30px;
  }
  .calculator_question footer .coolclimate{
    font-size: 10px;
  }
  
}
@media only screen and (max-width: 370px) {
  .calculator_question footer .units .description{
    display: none;
  }
}
.errorQuestion {
  color: #f44336;
  font-size: 0.75rem;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  margin-left: 0px;
  margin-right: 14px;
  margin-top: 6px;
}
.question-choice{
  display: flex;
  flex-direction: column;  
  min-width: 100%;
}
.question-choice .choice-alert{
  background: #FDF4E7;
  border-radius: 4px;
  margin: 10px 0px;
  padding: 6px 16px;
  display: flex;
  width: 413px;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  left: 320px;
  top: 780px;
  font-size: 14px;
}
.question-choice .choice-alert .MuiSvgIcon-root{
  color: #feab30;
  margin-right: 10px;
}
.question-choice .MuiButtonBase-root{
  max-width: 320px;
  margin-bottom: 10px;
  background-color: #fff;
  justify-content: left;
}
.question-choice.large .MuiButtonBase-root{
  max-width: 450px;
}
.question-choice .MuiButtonBase-root .MuiButton-label{
  text-transform: none;
  display: flex;
  justify-content: space-between;
}
.question-choice .MuiButtonBase-root.selected{
  border: 3px solid #78E176;
}

@media only screen and (max-width: 314px) {
  .question-choice .MuiButtonBase-root{
    width: auto;
  }
}

#root .question-choice .MuiButtonBase-root.with-hint .MuiButton-label, #root .question-choice .MuiFormControl-root.with-hint input{
  /**font-size: 20px;**/
}
#root .question-choice  .MuiButtonBase-root.with-hint  .MuiButton-label .hint{
  font-size: 16px;
	color: rgba(0, 0, 0, 0.54);
}
.question-choice .MuiButtonBase-root.with-hint{
  max-width: 420px;
}

#root .question-choice .MuiFormControl-root{
  max-width: 320px;
}
#root .question-choice .MuiFormControl-root.selected .MuiOutlinedInput-notchedOutline{
  border: 3px solid #78E176;
}
#root .question-choice .MuiOutlinedInput-root{
  background-color: #ffffff;
  height: 64px;  
}
.question-choice .MuiInputBase-root fieldset{
  top: -6px;
}
#root .question-choice .MuiFormControl-root.with-hint{
  max-width: 420px;  
  
}
#root .question-choice .MuiInputAdornment-root p{
  font-size: 16px;
}

.question-choice .MuiInputBase-formControl input {
	font-size:18px;
}
.question-hint{ 
  display: flex;
  align-items: center;
  height: 32px;
  margin-top: 10px;
}
.question-hint span{
  font-size: 14px;
  color: #656A6F;
  line-height: 140.62%;  
	font-weight: 500;
} 
.question-hint .MuiSvgIcon-root{
  height: 12px;
  color: #656A6F;
}
.vehicle { 
 display: flex;
 flex-direction: column; 
 border-top: 1px solid #00000066;
 margin-top: 33px;
}

#root .vehicle label{
  font-size: 20px; 
  margin-top: 30px;
  margin-bottom: 10px;
}

.vehicle .MuiInputBase-root{
  background-color: #ffffff;
}


#root .question-vehicles .vehicle h1 {
	font-size:32px;
	margin-bottom:0px;
}

.vehicle .per-gallon{
  max-width: 323px;
}

.vehicle .per-gallon fieldset{
  top:-6px;
}
.vehicle .driven{
  max-width: 420px; 
}
.vehicle .driven fieldset{
  top:-6px;
}

.vehicle .MuiToggleButton-label{
	font-size: 16px;
	line-height: 100%;
	text-transform: none;
	height: 30px;
	font-weight: normal;
}
.vehicle .MuiToggleButton-label span{

}
.vehicle .MuiToggleButton-root{
	color: rgba(0, 0, 0, 0.54);
	border-color: rgba(0, 0, 0, 0.25);
}

.vehicle .MuiToggleButton-root.Mui-selected{
	color: #041E10;
}
.vehicle.MuiToggleButton-root.Mui-selected:hover{
	background-color: rgba(0, 0, 0, 0.12);
}

#root .question-vehicles .MuiFormControl-root.driven > div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: center;
  align-content: stretch;
}

#root .question-vehicles .MuiFormControl-root.driven > div > div {
	margin-right:10px;
}

#root .question-vehicles .MuiOutlinedInput-input {
	font-size: 18px;
}
.question-vehicles .number-vehicles .MuiInputBase-root{
  background: #FFFFFF;
  width: 250px;
}
.question-multiple{
  display: flex;
  flex-direction: column;
  
}
.question-multiple label{
  font-size: 24px;
	margin-bottom: 10px;
}
.question-multiple .MuiInputBase-root{
  background: #fff;
}
.question-multiple fieldset{
  top: -6px;  
}
.question-multiple .question-control{
  width: 100%;
  display: flex;
  align-items: center;
}
.question-multiple .MuiInputBase-root{
  max-width: 420px;
  width: 100%;
}
.question-multiple .question{
  margin: 15px 0;
}
.question-multiple .question:nth-last-child(1){
  margin-bottom: 0px;
}

.question-multiple .question-control .tooltip-button {
	margin-left:10px;
}

.question-advance .question-multiple input {
	font-size:18px;
}

.question-multiple .question-control > div {
	height:64px;
}

.question-multiple .MuiOutlinedInput-input {
	font-size: 18px;
}
.question-advance{
  display: flex;
  flex-direction: column;
}
.question-advance .engine_type{
  margin-bottom: 20px;
}
.question-helper .MuiToggleButton-label{
  font-size: 16px;
  line-height: 100%;
  text-transform: none;
  height: 30px;
	font-weight: normal;
}
.question-helper .MuiToggleButton-root{
  color: rgba(0, 0, 0, 0.54);
  border-color: rgba(0, 0, 0, 0.25);
}
.question-helper .MuiToggleButton-root.Mui-selected{
  color: #041E10;
}
.question-helper .MuiToggleButton-root.Mui-selected:hover{
  background-color: rgba(0, 0, 0, 0.12);
}

.question-zipcode .MuiInputBase-root{
  background: #fff;
}
.question-zipcode .MuiFormControl-root input{
  color: #00151E;
}
.question-zipcode.MuiAutocomplete-root{
  width: 400px;
}
@media only screen and (max-width: 576px) {
  .question-zipcode.MuiAutocomplete-root{
    width: 100%;
  }
}
.question-slider.MuiFormControl-root{
  margin-top:20px;
}
.question-slider-value{
  font-size: 14px;
}
.dashboard_table{
  display: table;
  color: #041E10;  
  font-size: 14px;
  width: 100%;
}
.dashboard_table header{
  height: 60px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #F9F9FD;
  background: #ffffff;
  align-items: center;
	font-weight: 600;
	-webkit-border-top-left-radius: 4px;
	-webkit-border-top-right-radius: 4px;
	-moz-border-radius-topleft: 4px;
	-moz-border-radius-topright: 4px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.dashboard_table header .header-item{
  margin-left:16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.dashboard_table header .header-item span{
  max-width: 80px;
}
.dashboard_table header .header-tons{
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.dashboard_table header .header-tons span.total{
  font-weight: normal;
	opacity: 0.6;
}
.dashboard_table header .header-item .MuiSvgIcon-root{
  color: #5C6068;
  margin-left: 10px;
}
.dashboard_table header .header-tons .MuiSvgIcon-root{
  color: #5C6068;
  margin-left: 10px;
}

.dashboard_table footer{
  height: 60px;
  display: flex;
  width: 100%;
  background: #ffffff;
  align-items: center;
  justify-content: space-between;
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.dashboard_table footer .footer-label{
  
  text-align: right;
  margin-left: 16px;
  font-weight: bold;
	font-size:14px;
}
.dashboard_table footer .footer-value{
	text-align: right;
	margin-right: 16px;
	font-weight: 600;
	font-family: 'Space Mono';
	font-size:16px;
}
.dashboard_table .row{
  height: 60px;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #F9F9FD;
  background: #ffffff;
  align-items: center;
  
}
.dashboard_table .row .item{
  display: flex;
  width: 209px;
  margin-left:16px ;
  font-size: 16px;
  
}
.dashboard_table .row .icon{

}

.dashboard_table .row .graph{
  width: 100px;
  margin-right: 10px;
  margin-left: 10px;
}
.dashboard_table .row .value{
  font-size: 16px;
  font-family: Space Mono;
	font-weight:700;
  text-align: right;
  display: flex;
  align-items: baseline;
  justify-content: end;
  flex: 1 1;
  margin-right: 16px;
}
.dashboard_table .row .value span{
  font-family: 'Montserrat', sans-serif;
	opacity: 0.6;
	font-size:14px;
	font-weight:400;
}
.dashboard_table .MuiSvgIcon-root.home{
  color: #239B8A;
}

.dashboard_table .MuiSvgIcon-root.transport{
  color: #34638B;
  transform: matrix(0.71, 0.73, -0.69, 0.71, 0, 0);
}

.dashboard_table .MuiSvgIcon-root.food{
  color: #BBBE2C;
}

.dashboard_table .MuiSvgIcon-root.goods{
  color: #91959B;
}

.dashboard_table .MuiSvgIcon-root.services{
  color: #5C6068;
}
.dashboard_table .row .graph .line{
  height: 10px;
}
.dashboard_table .row .graph.home .line{
  background: #239B8A;
}

.dashboard_table .row .graph.transport .line{
  background: #34638B;
}
.dashboard_table .row .graph.food .line{
  background: #BBBE2C;
}
.dashboard_table .row .graph.goods .line{
  background: #91959B;
}
.dashboard_table .row .graph.services .line{
  background: #5C6068;
}
@media only screen and (max-width: 845px) {
  .dashboard_table{
    width: 100%;
  }
}
@media only screen and (max-width: 515px) {
  .dashboard_table .graph{
    display: none;
  }
  .dashboard_table .icon{
    display: none;
  }
  .dashboard_table{
    min-width: auto;
  }
}
@media only screen and (max-width: 370px) {
  .dashboard_table .header-tons >span{
    display: none;
  }
  .dashboard_table .row .item{
    width: auto;
  }
  .dashboard_table .row .value{
    
  }
}
.responses{
  background: #F9F9FD;
  display: flex;
  flex: 1 1;      
  padding: 20px 0 40px;    
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.responses .controls-filter{
  max-width: 600px;
}
.responses .controls-embed{
    display: none;
}
.responses .controls{
  margin-right: 28px;
}
.responses .controls-filter.MuiAccordion-root.Mui-expanded,
.responses .controls-filter.MuiAccordion-root
  {
  display: block;
  width: calc(100% - 20px);
  margin-left:10px;
  margin-right:10px;
  margin-bottom: 10px;
  margin-top: 0px;
}
.responses .controls-filter .MuiAccordionDetails-root{
  flex-direction: column;    
  
}

.responses .controls-filter .section_selection{
  justify-content: space-between;
}
.responses .controls-filter.MuiPaper-root{
  /**box-shadow: none;**/
  border-radius: 4px;
} 
.responses .controls-filter.MuiPaper-root::before{
  background-color: transparent;
} 
.responses .table{
  width: 100%;
  max-width: 600px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
}
.edit-section{
  position: fixed;
  left: 15px;
  bottom:15px;
}
#root .edit-section .edit-button.MuiButtonBase-root.MuiButton-contained{
  height: 42px;
}

.responses footer.nav .navigation-buttons {
  margin-left: 20px;
  margin-top:  24px;
  margin-bottom: 38px;
}
.responses footer.nav {
  display: flex;
  justify-content: space-between;
  width: 100%;    
  pointer-events: none;
  position: fixed;
  left: 0px;
  bottom: 0px;
}

#root .responses footer.nav .MuiButton-root {
  height: 42px;
	font-size:16px;
  pointer-events: initial;
}

#root .responses footer.nav .MuiButton-root .MuiButton-label{
	font-size:16px;
}

#root .responses footer.nav a.MuiButton-root {
  text-decoration: none;
}

#root .responses footer.nav .navigation-buttons .MuiButtonBase-root {
  padding:0px;
  min-width: 42px;
  max-width: 42px;
	margin-right:2px;
	border-radius:0px;
}
#root .responses footer.nav .navigation-buttons .nav-btn-menu.MuiButtonBase-root {
  max-width: none;
  max-width: initial;
  padding-right: 10px;
  padding-left: 10px;
  border-radius:5px;
  margin-left: 10px;
}
#root .responses footer.nav .navigation-buttons .nav-btn-menu.MuiButtonBase-root span.text {
  height: 25px;
  padding-left: 5px;
  display: flex;
    justify-content: center;
    align-items: center;
}

#root .responses footer.nav .navigation-buttons .nav-btn-prev {
	-webkit-border-top-left-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

#root .responses footer.nav .navigation-buttons .nav-btn-next {
	-webkit-border-top-right-radius: 5px;
	-webkit-border-bottom-right-radius: 5px;
	-moz-border-radius-topright: 5px;
	-moz-border-radius-bottomright: 5px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

#root .responses footer.nav .navigation-buttons .nav-btn-prev svg{
	position:relative;
	left:5px;
}

@media only screen and (max-width: 845px) {
  .responses .controls-embed .section_selection{
    justify-content: space-between;
  }  
  .responses .controls-embed{
    display: block;
    width: calc(100% - 20px);
    margin-left:10px;
    margin-right:10px;
    margin-bottom: 10px;    
    background-color: #041e10;
    color: white;
}
  
  .responses .table{
    width: calc(100% - 20px);
    margin-left:10px;
    margin-right:10px;
    display: flex;
    justify-content: center;
    max-width: 600px;
  }
  .responses .controls{
    display: none;
  }
  
  
}
