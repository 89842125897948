.calculator_question{
  background: #F9F9FD;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 60px;
}
.calculator_question .wrapper{
  max-width: 800px;  
  min-width: 800px;  
  display: flex;
  flex-direction: column;
  flex:1;
  margin-bottom: 50px;
  margin-right: 20px;
  margin-left: 20px;
}
@media only screen and (max-width: 800px) {
  .calculator_question .wrapper{
    width: calc(100% - 40px);
    max-width: none;
    min-width: auto;
  }
}
.calculator_question header{
  font-size: 32px;
  line-height: 140%;
  /* identical to box height, or 50px */
  color: #161C24;
  margin-top: 40px;
  margin-bottom:21px;
}

.calculator_question .MuiPaper-root.info {
	background: rgba(0, 0, 0, 0.04);
	font-size: 16px;
  line-height: 148%;
  margin-bottom: 25px;
  padding: 24px 20px;
}

.calculator_question .MuiPaper-root.info > p:first-child {
	margin-top:0px;
}

.calculator_question .MuiPaper-root.info > p:last-child {
	margin-bottom:0px;
}

.calculator_question .MuiButtonBase-root.submit {
  margin-top: 25px;
  max-width: 140px;
}

#root .calculator_question .MuiButtonBase-root.submit .MuiButton-label{
	font-weight: 500;
	font-size:16px;
}

.calculator_question footer{
  display: flex;
  justify-content: space-between;
  width: 100%;    
  pointer-events: none;
  position: fixed;
  left: 0px;
  bottom: 0px;
}

#root .calculator_question footer .MuiButton-root {
  height: 42px;
	font-size:16px;
  pointer-events: initial;
  transition: none;
}

#root .calculator_question footer .MuiButton-root .MuiButton-label{
	font-size:16px;
}

#root .calculator_question footer a.MuiButton-root {
  text-decoration: none;
}

#root .calculator_question footer .navigation-buttons .MuiButtonBase-root {
  padding:0px;
  min-width: 42px;
  max-width: 42px;
	margin-right:2px;
	border-radius:0px;
}

#root .calculator_question footer .navigation-buttons .nav-btn-prev {
	-webkit-border-top-left-radius: 5px;
	-webkit-border-bottom-left-radius: 5px;
	-moz-border-radius-topleft: 5px;
	-moz-border-radius-bottomleft: 5px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

#root .calculator_question footer .navigation-buttons .nav-btn-next {
	-webkit-border-top-right-radius: 5px;
	-webkit-border-bottom-right-radius: 5px;
	-moz-border-radius-topright: 5px;
	-moz-border-radius-bottomright: 5px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

#root .calculator_question footer .navigation-buttons .nav-btn-prev svg{
	position:relative;
	left:5px;
}

.calculator_question footer .aux-buttons {
	margin-right: 20px;
  margin-top:  24px;
  margin-bottom: 38px;
}
.calculator_question footer .coolclimate{
  position: absolute;
  right: 20px;
  bottom: 10px;
  font-size: 12px;
  pointer-events: all;
  
}

.calculator_question footer .navigation-buttons {
  margin-left: 20px;
  margin-top:  24px;
  margin-bottom: 38px;
}

.calculator_question footer .aux-buttons .units{
	background:#F9F9FD;
}

.calculator_question footer .aux-buttons .need-help{
  margin-left: 10px;
}
.calculator_question .wrapper-question-helpers{
  display: flex;  
  margin-bottom: 15px;
  height: 36px;
}

button.submit {
	box-shadow:none;
}
.skiplink{
  font-size: 14px;
  margin-top: 10px;
  text-decoration-line: underline;
  cursor: pointer;
}
@media only screen and (max-width: 576px) {
  .calculator_question footer .need-help{
    display: none;
  }  
  .calculator_question footer{
    background-color: white;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
  }

  .calculator_question footer .aux-buttons {
    margin-right: 10px;
    margin-top:  24px;
    margin-bottom: 30px;
  }
  
  .calculator_question footer .navigation-buttons {
    margin-left: 10px;
    margin-top:  24px;
    margin-bottom: 30px;
  }
  .calculator_question footer .coolclimate{
    font-size: 10px;
  }
  
}
@media only screen and (max-width: 370px) {
  .calculator_question footer .units .description{
    display: none;
  }
}
.errorQuestion {
  color: #f44336;
  font-size: 0.75rem;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  margin-left: 0px;
  margin-right: 14px;
  margin-top: 6px;
}