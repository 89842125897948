.dashboard_table{
  display: table;
  color: #041E10;  
  font-size: 14px;
  width: 100%;
}
.dashboard_table header{
  height: 60px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #F9F9FD;
  background: #ffffff;
  align-items: center;
	font-weight: 600;
	-webkit-border-top-left-radius: 4px;
	-webkit-border-top-right-radius: 4px;
	-moz-border-radius-topleft: 4px;
	-moz-border-radius-topright: 4px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.dashboard_table header .header-item{
  margin-left:16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.dashboard_table header .header-item span{
  max-width: 80px;
}
.dashboard_table header .header-tons{
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.dashboard_table header .header-tons span.total{
  font-weight: normal;
	opacity: 0.6;
}
.dashboard_table header .header-item .MuiSvgIcon-root{
  color: #5C6068;
  margin-left: 10px;
}
.dashboard_table header .header-tons .MuiSvgIcon-root{
  color: #5C6068;
  margin-left: 10px;
}

.dashboard_table footer{
  height: 60px;
  display: flex;
  width: 100%;
  background: #ffffff;
  align-items: center;
  justify-content: space-between;
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.dashboard_table footer .footer-label{
  
  text-align: right;
  margin-left: 16px;
  font-weight: bold;
	font-size:14px;
}
.dashboard_table footer .footer-value{
	text-align: right;
	margin-right: 16px;
	font-weight: 600;
	font-family: 'Space Mono';
	font-size:16px;
}
.dashboard_table .row{
  height: 60px;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #F9F9FD;
  background: #ffffff;
  align-items: center;
  
}
.dashboard_table .row .item{
  display: flex;
  width: 209px;
  margin-left:16px ;
  font-size: 16px;
  
}
.dashboard_table .row .icon{

}

.dashboard_table .row .graph{
  width: 100px;
  margin-right: 10px;
  margin-left: 10px;
}
.dashboard_table .row .value{
  font-size: 16px;
  font-family: Space Mono;
	font-weight:700;
  text-align: right;
  display: flex;
  align-items: baseline;
  justify-content: end;
  flex: 1;
  margin-right: 16px;
}
.dashboard_table .row .value span{
  font-family: 'Montserrat', sans-serif;
	opacity: 0.6;
	font-size:14px;
	font-weight:400;
}
.dashboard_table .MuiSvgIcon-root.home{
  color: #239B8A;
}

.dashboard_table .MuiSvgIcon-root.transport{
  color: #34638B;
  transform: matrix(0.71, 0.73, -0.69, 0.71, 0, 0);
}

.dashboard_table .MuiSvgIcon-root.food{
  color: #BBBE2C;
}

.dashboard_table .MuiSvgIcon-root.goods{
  color: #91959B;
}

.dashboard_table .MuiSvgIcon-root.services{
  color: #5C6068;
}
.dashboard_table .row .graph .line{
  height: 10px;
}
.dashboard_table .row .graph.home .line{
  background: #239B8A;
}

.dashboard_table .row .graph.transport .line{
  background: #34638B;
}
.dashboard_table .row .graph.food .line{
  background: #BBBE2C;
}
.dashboard_table .row .graph.goods .line{
  background: #91959B;
}
.dashboard_table .row .graph.services .line{
  background: #5C6068;
}
@media only screen and (max-width: 845px) {
  .dashboard_table{
    width: 100%;
  }
}
@media only screen and (max-width: 515px) {
  .dashboard_table .graph{
    display: none;
  }
  .dashboard_table .icon{
    display: none;
  }
  .dashboard_table{
    min-width: auto;
  }
}
@media only screen and (max-width: 370px) {
  .dashboard_table .header-tons >span{
    display: none;
  }
  .dashboard_table .row .item{
    width: auto;
  }
  .dashboard_table .row .value{
    
  }
}