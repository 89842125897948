.question-choice{
  display: flex;
  flex-direction: column;  
  min-width: 100%;
}
.question-choice .choice-alert{
  background: #FDF4E7;
  border-radius: 4px;
  margin: 10px 0px;
  padding: 6px 16px;
  display: flex;
  width: 413px;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  left: 320px;
  top: 780px;
  font-size: 14px;
}
.question-choice .choice-alert .MuiSvgIcon-root{
  color: #feab30;
  margin-right: 10px;
}
.question-choice .MuiButtonBase-root{
  max-width: 320px;
  margin-bottom: 10px;
  background-color: #fff;
  justify-content: left;
}
.question-choice.large .MuiButtonBase-root{
  max-width: 450px;
}
.question-choice .MuiButtonBase-root .MuiButton-label{
  text-transform: none;
  display: flex;
  justify-content: space-between;
}
.question-choice .MuiButtonBase-root.selected{
  border: 3px solid #78E176;
}

@media only screen and (max-width: 314px) {
  .question-choice .MuiButtonBase-root{
    width: auto;
  }
}

#root .question-choice .MuiButtonBase-root.with-hint .MuiButton-label, #root .question-choice .MuiFormControl-root.with-hint input{
  /**font-size: 20px;**/
}
#root .question-choice  .MuiButtonBase-root.with-hint  .MuiButton-label .hint{
  font-size: 16px;
	color: rgba(0, 0, 0, 0.54);
}
.question-choice .MuiButtonBase-root.with-hint{
  max-width: 420px;
}

#root .question-choice .MuiFormControl-root{
  max-width: 320px;
}
#root .question-choice .MuiFormControl-root.selected .MuiOutlinedInput-notchedOutline{
  border: 3px solid #78E176;
}
#root .question-choice .MuiOutlinedInput-root{
  background-color: #ffffff;
  height: 64px;  
}
.question-choice .MuiInputBase-root fieldset{
  top: -6px;
}
#root .question-choice .MuiFormControl-root.with-hint{
  max-width: 420px;  
  
}
#root .question-choice .MuiInputAdornment-root p{
  font-size: 16px;
}

.question-choice .MuiInputBase-formControl input {
	font-size:18px;
}