.screen_login{
    height: auto;  
    display: flex;
    align-items: center;
    justify-content: center;
		background:#041e10;
}
.screen_login .wrapper{
    flex: 1;
    max-width: 400px;
    display: flex;
    flex-direction: column;
		
		background: #F9F9FD;
		border-radius: 4px;
		padding: 20px 20px 40px;
}
.screen_login .wrapper form{
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}
.screen_login h1{
    text-align: center;
    font-size: 32px;
		font-weight:500;
}
.screen_login footer{
    margin-top: 30px;
    text-align: center;
    font-size: 16px;
}
.screen_login .ui.message {
    text-align: center;
}

.screen_login .MuiInputBase-input {
	border-radius:4px;
	background-color:#ffffff;
}


#root .screen_login button.MuiButtonBase-root .MuiButton-label {
    font-size: 16px;
}
@media screen and ( min-height: 565px ) {

    .screen_login{
        height: 100vh;
    }
}
