.nav_component{
    display: flex;
    background: #041E10;
		flex-direction: column;
		position: relative;
		width: 100%;
}
.nav_component header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
		padding: 20px 40px 0;
		z-index: 10;
}
.nav_footprint-graph .title{
    color: #FFFFFF;
    font-size: 16px;    
}

.nav_component .separator{
    display: none;
}
.nav_component .nav_logo,.nav_component .nav_account {
    cursor: pointer;
}

.nav_component .nav_logo{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: center;
  align-content: stretch;
}

.nav_component .nav_logo img{
    height: 20px;    
    left: 40px;
    top: 15px;
    border-radius: 0px;
}
.nav_component .nav_account{
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: 10;
}
.nav_component .nav_account .nav_account_avatar{
	display:none;
    /**width: 30px;
    height: 30px;
    border-radius: 30px;
    margin-right: 10px;**/
}
.nav_component .nav_account .nav_account_name{
    color: #FFFFFF;
    font-size: 18px;
}

.nav_account > div > button {
	text-transform: none;
}

.nav_component .nav_account .nav_account_menu{
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 5px;
}
.nav_component .nav_account i.icon{
    color: #FFFFFF;
    font-size: 19px;
    position: relative;    
}
.nav_account_menu .MuiButton-label{
    color: #FFFFFF;
}
.nav_component footer .MuiPaper-root{
    background-color: transparent;
    max-width: 800px;
    width: 100%;
		 padding-top: 20px;
     padding:0px 0px 15px;
}

.nav_component footer .MuiStepLabel-iconContainer .completed {
    color: #78E176;
}

.nav_component footer .MuiStepLabel-iconContainer .circle{
    width: 20px;
    height: 20px;
    background-color:rgba(255, 255, 255, 0.11);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Space Mono';
    line-height: 100%;
    color: #041E10;
}
.nav_component footer .MuiStepLabel-iconContainer .active .circle{
    background-color: #78E176;
}

.nav_component footer .MuiStepLabel-label{
    color: rgba(255, 255, 255, 0.3);
    text-transform: uppercase;
    letter-spacing: 1px;
		font-weight: 400;
}

.nav_component footer .MuiStepLabel-label.MuiStepLabel-active,.nav_component footer .MuiStepLabel-label.MuiStepLabel-completed {
    color: #FFFFFF;
}

.nav_component footer .MuiStepConnector-line.MuiStepConnector-lineHorizontal{
    border-color: rgba(255, 255, 255, 0.25);
    border-width: 2px;
}
.nav_component footer{    
    display: flex;
    justify-content: center;
}
.nav_component footer .MuiStepIcon-root.MuiStepIcon-completed {
    color: #78E176;
}
.nav_component .MuiStep-root{
    height: 28px;
    display: flex;
		/** Test **/
    
	}
.nav_component .MuiStepLabel-root{
    cursor: pointer !important;
}

.nav_component .version{
	opacity: 0.6;
	font-size: 12px;
	text-align: center;
	margin: 14px 10px 5px;
	letter-spacing: 1px;
}
.nav_footprint{
    
    display: flex;
    justify-content: center;
}
.nav_footprint-graph{    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 377px;
    position: relative;
}


/**
@media only screen and (max-width: 420px) {
    .nav_component header{
        justify-content: center;
    }
    .nav_component header .nav_account{
        display: none;
        width: 0px;
    }
    .nav_component header .nav_logo{
        width: 0px;
        display: none;
    }
  }
**/

/** Small Devices: Portrait & Landscape **/
@media (max-width: 576px) {
  
	.nav_component {
		position:relative;
		width: auto;
	}
	
	.nav_component header {
		padding: 12px 20px 0;
	}
	
	.nav_component header .nav_logo img {
      width: 120px;
  }
	.nav_component .nav_account .nav_account_avatar {
		display:none;
	}
}
.navbar-select{
    display: none;
}

/** Small devices (landscape phones, less than 768px) **/
@media (max-width: 767.98px) {

}

@media only screen and (max-width: 845px) {
    .nav_footprint-graph{
        height: 500px;
    }
}

@media only screen and (max-width: 576px) {
    .nav_component .separator{
        display: block;
    }
    .nav_component footer{
      display: none;
    }
    .nav_component header{
        margin-bottom: 20px;
    }
    .nav_component header > div{
        width: 120px;
    }
    .nav_component .name{
        display: none;
    }
    .navbar-select{
        
        background-color: #F9F9FD;
        display: flex;
    }
    .navbar-select .MuiFormControl-root{
        width: 100vw;
    }
    #root .navbar-select .MuiInputBase-root{
        box-shadow: none;
    }
    .navbar-select .MuiOutlinedInput-notchedOutline{
        border-right-style: none;
        border-left-style: none;
        border-radius: 0px;        
    }
    .navbar-select .MuiSelect-selectMenu{
        text-transform: uppercase;        
    }    
    .navbar-select .navbar-select-menu{
        overflow-x: hidden;
    }
    
    div.MuiSelect-selectMenu >.navbar-select-option{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #1D352899;          
        height: 30px;
        
    }
    div.MuiSelect-selectMenu > .navbar-select-option .navbar-select-option-text{
        height: 20px;
        margin-left: 10px;
    }
    li > .navbar-select-option{
        display: flex;
        justify-content: flex-start;
        align-items: center;        
    }
    li > .navbar-select-option .navbar-select-option-text{
        margin-left: 10px;
        height: 20px;
    }
}

#alert-dialog-title h2{
    font-size: 1.75rem;
    font-weight: bold;
}
#leave-dialog .MuiDialog-paper{
   max-width: 500px; 
}