.screen_signup{
    min-height: auto;  
    display: flex;
    align-items: center;
    justify-content: center;
	background:#041e10;
    padding-bottom: 50px;
}
.screen_signup .wrapper{
    flex: 1;
    max-width: 400px;
    display: flex;
    flex-direction: column;
		
		background: #F9F9FD;
		border-radius: 4px;
		padding: 20px 20px 40px;
}
.screen_signup .wrapper form{
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}
.screen_signup h1{
    text-align: center; 
    font-size: 22px;
		font-weight:500;
}

.screen_signup .profile_field{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;    
}
.screen_signup footer{
    margin-top: 30px;
    text-align: center;
}
.screen_signup .check-avatar{
    margin-left: 10px;
}
.screen_signup .double-field{
    display: flex;
    justify-content: space-between;
}
#root .screen_signup .profile-button.MuiButtonBase-root{
    margin-top: 16px;
    margin-bottom: 8px;    
    justify-content: space-between;
    height: 56px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;   
		background:#ffffff;
		font-weight:normal;
}
.screen_signup .profile-button .MuiButton-label{
    text-transform: none;
}
.screen_signup .hide {
    display: none !important;

}

.screen_signup .MuiInputBase-input {
	border-radius:4px;
	background-color:#ffffff;
}

#root .screen_signup button.MuiButtonBase-root .MuiButton-label {
	font-size: 16px;
}
.terms {
    margin-top: 5px;
    font-size: .9rem;
    align-items: flex-end;
    line-height: 1.3rem;
}
.mfa {
    margin-top: 15px;
    font-size: .9rem;
    align-items: flex-end;
    line-height: 1.3rem;
}
@media screen and ( min-height: 780px ) {

    .screen_signup{
        height: 100vh;
    }
}
